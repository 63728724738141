window.addEventListener("load", () => {
  let isNavScrolling = false;
  let timeout;

  if (window.innerWidth > 1200 && window.innerWidth < 1600) {
    window.addEventListener("scroll", () => {
      clearTimeout(timeout);
      timeout = setTimeout(navScroll, 50); // Ajouter un délai de 50 ms
    });
  }

  function navScroll() {
      const nav = document.querySelector(".banner");
      const threshold = 10;
      if (window.scrollY > threshold && !isNavScrolling) {
          nav.classList.add('nav-scrolling');
          isNavScrolling = true;
      } else if (window.scrollY <= threshold && isNavScrolling) {
          nav.classList.remove('nav-scrolling');
          isNavScrolling = false;
      }
  }
});

window.addEventListener("load", () => {
  const menuItems = document.querySelectorAll('#menu-primary-navigation-1 .menu-item');
  menuItems.forEach(item => {
      item.setAttribute('data-bs-dismiss', 'offcanvas');
      item.setAttribute('aria-label', 'Close');
  });
});
