// import external dependencies
import jquery from 'jquery';
global.$ = global.jQuery = jquery;

import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/offcanvas';
import 'bootstrap/js/dist/collapse';


/* CUSTOM JS */

import './slider';
import './nav';
